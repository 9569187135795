<template>
  <el-form :inline="true" :model="formInline" size="small">
    <el-form-item label="服务器">
      <el-select v-model="formInline.serv">
        <el-option label="亚服" value="1"></el-option>
        <el-option label="欧服" value="2"></el-option>
        <el-option label="美服" value="3"></el-option>
        <el-option label="俄服" value="4"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="昵称">
      <el-input v-model="formInline.nick" placeholder="昵称"></el-input>
    </el-form-item>
    <el-form-item label="国籍">
      <el-select v-model="formInline.nation" placeholder="国籍">
        <el-option label="无" value=""></el-option>
        <el-option label="苏联" value="USSR"></el-option>
        <el-option label="美国" value="USA"></el-option>
        <el-option label="英国" value="UK"></el-option>
        <el-option label="法国" value="FRANCE"></el-option>
        <el-option label="德国" value="GERMANY"></el-option>
        <el-option label="中国" value="CHINA"></el-option>
        <el-option label="日本" value="JAPAN"></el-option>
        <el-option label="意大利" value="ITALY"></el-option>
        <el-option label="波兰" value="POLAND"></el-option>
        <el-option label="瑞典" value="SWEDEN"></el-option>
        <el-option label="捷克" value="CZECH"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="等级">
      <el-select v-model="formInline.level" placeholder="等级">
        <el-option label="无" value=""></el-option>
        <el-option label="X" value="10"></el-option>
        <el-option label="IX" value="9"></el-option>
        <el-option label="VIII" value="8"></el-option>
        <el-option label="VII" value="7"></el-option>
        <el-option label="VI" value="6"></el-option>
        <el-option label="V" value="5"></el-option>
        <el-option label="IV" value="4"></el-option>
        <el-option label="III" value="3"></el-option>
        <el-option label="II" value="2"></el-option>
        <el-option label="I" value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="类型">
      <el-select v-model="formInline.type" placeholder="类型">
        <el-option label="无" value=""></el-option>
        <el-option label="HT" value="HT"></el-option>
        <el-option label="MT" value="MT"></el-option>
        <el-option label="LT" value="LT"></el-option>
        <el-option label="TD" value="TD"></el-option>
        <el-option label="SPG" value="SPG"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" stripe fit="true" style="width: 100%">
    <el-table-column prop="name" label="车辆" min-width="160"></el-table-column>
    <el-table-column prop="tier" label="等级"></el-table-column>
    <el-table-column prop="batt" label="场次" sortable></el-table-column>
    <el-table-column label="效率" sortable>
      <template #default="scope">
        <div :class="processPR(scope.row.pr)">
          {{ scope.row.pr }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="胜率" sortable>
      <template #default="scope">
        <div :class="processWin(scope.row.wins)">
          {{ scope.row.wins }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="tvr" label="输出比" sortable></el-table-column>
    <el-table-column prop="dmdt" label="输出" sortable></el-table-column>
    <el-table-column prop="dmrv" label="受伤" sortable></el-table-column>
    <el-table-column prop="kd" label="K/D" sortable></el-table-column>
    <el-table-column prop="frags" label="击毁" sortable></el-table-column>
    <el-table-column prop="surv" label="幸存率" sortable></el-table-column>
    <el-table-column prop="spot" label="侦查" sortable></el-table-column>
    <el-table-column prop="xp" label="经验" sortable></el-table-column>
  </el-table>
</template>

<script>
import { ElMessage } from "element-plus";
import common from "@/common";

export default {
  name: "Vehicle",

  created() {},
  methods: {
    onSubmit() {
      if (this.formInline.nick.length <= 0) {
        ElMessage.error("昵称不能为空！");
        return;
      }
      this.tableData = [];
      this.getRecord();
    },
    processVehicle(response) {
      var trd = [];
      if (response.data.code == 40401) {
        ElMessage.error("找不到该用户");
        return;
      } else if (response.data.code == 40403) {
        ElMessage.error("未收录该用户数据，请稍后再试");
        return;
      }
      var trrd = response.data.data;
      for (var i = 0; i < trrd.length; i++) {
        var td = trrd[i];
        trd[i] = {};
        trd[i].name = td.name;
        trd[i].tier = td.tier;
        trd[i].batt = td.batt;
        trd[i].pr = td.pr;
        trd[i].wins = ((100.0 * td.wins) / td.batt).toFixed(2) + "%";
        trd[i].surv = ((100.0 * td.surv) / td.batt).toFixed(2) + "%";
        trd[i].dmdt = td.dmdt;
        trd[i].dmrv = td.dmrv;
        trd[i].tvr = (td.dmdt / td.dmrv).toFixed(2);
        trd[i].frags = td.frags;
        trd[i].kd = (td.frags / (td.batt - td.surv)).toFixed(2);
        trd[i].spot = td.spot;
        trd[i].xp = td.xp;
      }
      this.tableData = trd;
    },
    getRecord() {
      this.axios({
        methods: "get",
        url: "getVehicle",
        params: {
          serv: this.formInline.serv,
          nick: this.formInline.nick,
          nation: this.formInline.nation,
          level: this.formInline.level,
          type: this.formInline.type,
        },
      }).then(this.processVehicle);
    },
    processPR(value) {
      return common.calculatePersonRate(value);
    },
    processWin(value) {
      return common.calculateWinRate(value);
    },
  },
  data() {
    return {
      formInline: {
        serv: "1",
        nick: "",
        nation: "",
        level: "",
        type: "",
      },
      tableData: [],
    };
  },
};
</script>

<style >
#Record {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* .VeryBad {
  text-align: center;
  color: #930d0d !important;
}

.Bad {
  text-align: center;
  color: #cd3333 !important;
}

.BelowAverage {
  text-align: center;
  color: #cc7a00 !important;
}

.Average {
  text-align: center;
  color: #ccb800 !important;
}

.AboveAverage {
  text-align: center;
  color: #849b24 !important;
}

.Good {
  text-align: center;
  color: #4d7326 !important;
}

.VeryGood {
  text-align: center;
  color: #4099bf !important;
}

.Great {
  text-align: center;
  color: #3972c6 !important;
}

.Unicum {
  text-align: center;
  color: #793db6 !important;
}

.SuperUnicum {
  text-align: center;
  color: #401070 !important;
} */
</style>
