<template>
  <el-form
    :inline="true"
    :model="formInline"
    action="javascript:;"
    size="small"
  >
    <el-form-item label="服务器">
      <el-select v-model="formInline.serv">
        <el-option label="亚服" value="1"></el-option>
        <el-option label="欧服" value="2"></el-option>
        <el-option label="美服" value="3"></el-option>
        <el-option label="俄服" value="4"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="昵称">
      <el-input v-model="formInline.nick" placeholder="昵称"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="accountData"
    :stripe="true"
    :fit="true"
    :border="true"
    :show-header="false"
    style="width: 100%"
  >
    <el-table-column prop="key1"></el-table-column>
    <el-table-column prop="value1"></el-table-column>
    <el-table-column prop="key2"></el-table-column>
    <el-table-column prop="value2"></el-table-column>
  </el-table>
  <el-divider></el-divider>
  <el-table :data="tierData" stripe fit="true" style="width: 100%">
    <el-table-column prop="tier" label="等级"></el-table-column>
    <el-table-column prop="type" label="类型"></el-table-column>
    <el-table-column prop="batt" label="场次" sortable></el-table-column>
    <el-table-column prop="wins" label="胜率" sortable></el-table-column>
    <el-table-column prop="surv" label="幸存率" sortable></el-table-column>
    <el-table-column prop="tvr" label="输出比" sortable></el-table-column>
    <el-table-column prop="dmdt" label="输出" sortable></el-table-column>
    <el-table-column prop="dmrv" label="受伤" sortable></el-table-column>
    <el-table-column prop="kd" label="K/D" sortable></el-table-column>
    <el-table-column prop="spot" label="侦查" sortable></el-table-column>
    <el-table-column prop="xp" label="经验" sortable></el-table-column>
  </el-table>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "Account",
  created() {},
  mounted() {},

  methods: {
    onSubmit() {
      if (this.formInline.nick.length <= 0) {
        ElMessage.error("昵称不能为空！");
        return;
      }
      this.accountData = [];
      this.tierData = [];
      this.getRecord();
    },
    parserAccount(response) {
      var trd = [];
      if (response.data.code == 40401) {
        ElMessage.error("找不到该用户");
        return;
      } else if (response.data.code == 40403) {
        ElMessage.error("未收录该用户数据，请稍后再试");
        return;
      }
      var trrd = response.data.data.trrd;
      for (var i = 0; i < trrd.length; i++) {
        var td = trrd[i];
        trd[i] = {};
        trd[i].tier = td.tier;
        trd[i].type = td.type;
        trd[i].batt = td.batt;
        trd[i].wins = ((100.0 * td.wins) / td.batt).toFixed(2) + "%";
        trd[i].surv = ((100.0 * td.surv) / td.batt).toFixed(2) + "%";
        trd[i].dmdt = td.dmdt;
        trd[i].dmrv = td.dmrv;
        trd[i].tvr = (td.dmdt / td.dmrv).toFixed(2);
        trd[i].frags = td.frags;
        trd[i].kd = (td.frags / (td.batt - td.surv)).toFixed(2);
        trd[i].spot = td.spot;
        trd[i].xp = td.xp;
      }

      var acrd = response.data.data.acrd;
      var acd = [];
      acd[0] = {};
      acd[0].key1 = "场次";
      acd[0].value1 = acrd.batt;
      acd[0].key2 = "幸存率";
      acd[0].value2 = ((100.0 * acrd.surv) / acrd.batt).toFixed(2) + "%";
      acd[1] = {};
      acd[1].key1 = "胜场";
      acd[1].value1 = acrd.wins;
      acd[1].key2 = "胜率";
      acd[1].value2 = ((100.0 * acrd.wins) / acrd.batt).toFixed(2) + "%";
      acd[2] = {};
      acd[2].key1 = "击毁";
      acd[2].value1 = acrd.frags;
      acd[2].key2 = "场均击毁";
      acd[2].value2 = (acrd.frags / acrd.batt).toFixed(2);
      acd[3] = {};
      acd[3].key1 = "侦查";
      acd[3].value1 = acrd.spot;
      acd[3].key2 = "场均侦查";
      acd[3].value2 = (acrd.spot / acrd.batt).toFixed(2);
      acd[4] = {};
      acd[4].key1 = "场均输出";
      acd[4].value1 = acrd.dmdt;
      acd[4].key2 = "输出受伤比";
      acd[4].value2 = (acrd.dmdt / acrd.dmrv).toFixed(2);
      acd[5] = {};
      acd[5].key1 = "场均受伤";
      acd[5].value1 = acrd.dmrv;
      acd[5].key2 = "K/D";
      acd[5].value2 = (acrd.frags / (acrd.batt - acrd.surv)).toFixed(2);
      acd[6] = {};
      acd[6].key1 = "场均经验";
      acd[6].value1 = acrd.xp;
      acd[6].key2 = "命中率";
      acd[6].value2 = ((100.0 * acrd.hits) / acrd.shots).toFixed(2) + "%";

      this.tierData = trd;
      this.accountData = acd;
    },
    getRecord() {
      this.axios({
        methods: "get",
        url: "getAccount",
        params: {
          nick: this.formInline.nick,
          serv: this.formInline.serv,
        },
      }).then(this.parserAccount);
    },
  },
  data() {
    return {
      formInline: {
        serv: "1",
        nick: "",
      },
      accountData: [],
      tierData: [],
    };
  },
};
</script>

<style>
#Record {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
