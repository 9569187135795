<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="帐号战绩" name="account"><acc /></el-tab-pane>
    <el-tab-pane label="单车战绩" name="vehicle"><vec /></el-tab-pane>
    <el-tab-pane label="战斗记录" name="record"><rec /></el-tab-pane>
  </el-tabs>
  <div>
    <el-link href="https://beian.miit.gov.cn/" target="_blank"
      >京ICP备2021034480号-2</el-link
    >
  </div>
</template>

<script>
import Record from "./Record.vue";
import Account from "./Account.vue";
import Vehicle from "./Vehicle.vue";
export default {
  name: "App",
  components: {
    acc: Account,
    vec: Vehicle,
    rec: Record,
  },
  data() {
    return {
      activeName: "account",
    };
  },
  mounted() {},
};
</script>
