<template>
  <el-form :inline="true" :model="playerParam" size="small">
    <el-form-item label="服务器">
      <el-select v-model="playerParam.serv">
        <el-option label="亚服" value="1"></el-option>
        <el-option label="欧服" value="2"></el-option>
        <el-option label="美服" value="3"></el-option>
        <el-option label="俄服" value="4"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="昵称">
      <el-input v-model="playerParam.nick" placeholder="昵称"></el-input>
    </el-form-item>
    <el-form-item label="国籍">
      <el-select v-model="playerParam.nation" placeholder="国籍">
        <el-option label="无" value=""></el-option>
        <el-option label="苏联" value="USSR"></el-option>
        <el-option label="美国" value="USA"></el-option>
        <el-option label="英国" value="UK"></el-option>
        <el-option label="法国" value="FRANCE"></el-option>
        <el-option label="德国" value="GERMANY"></el-option>
        <el-option label="中国" value="CHINA"></el-option>
        <el-option label="日本" value="JAPAN"></el-option>
        <el-option label="意大利" value="ITALY"></el-option>
        <el-option label="波兰" value="POLAND"></el-option>
        <el-option label="瑞典" value="SWEDEN"></el-option>
        <el-option label="捷克" value="CZECH"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="等级">
      <el-select v-model="playerParam.level" placeholder="等级">
        <el-option label="无" value=""></el-option>
        <el-option label="X" value="10"></el-option>
        <el-option label="IX" value="9"></el-option>
        <el-option label="VIII" value="8"></el-option>
        <el-option label="VII" value="7"></el-option>
        <el-option label="VI" value="6"></el-option>
        <el-option label="V" value="5"></el-option>
        <el-option label="IV" value="4"></el-option>
        <el-option label="III" value="3"></el-option>
        <el-option label="II" value="2"></el-option>
        <el-option label="I" value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="类型">
      <el-select v-model="playerParam.type" placeholder="类型">
        <el-option label="无" value=""></el-option>
        <el-option label="HT" value="HT"></el-option>
        <el-option label="MT" value="MT"></el-option>
        <el-option label="LT" value="LT"></el-option>
        <el-option label="TD" value="TD"></el-option>
        <el-option label="SPG" value="SPG"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" :stripe="true" :fit="true" style="width: 100%">
    <el-table-column prop="tsct" label="时间"></el-table-column>
    <el-table-column prop="nick" label="昵称"></el-table-column>
    <el-table-column prop="name" label="车辆" min-width="160"></el-table-column>
    <el-table-column prop="pr" label="效率" sortable>
      <template #default="scope">
        <div :class="processPR(scope.row.pr)">
          {{ scope.row.pr }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="batt" label="场次"></el-table-column>
    <el-table-column prop="wins" label="胜利"></el-table-column>
    <el-table-column prop="surv" label="幸存"></el-table-column>
    <el-table-column prop="dmdt" label="输出"></el-table-column>
    <el-table-column prop="dmrv" label="受伤"></el-table-column>
    <el-table-column prop="frags" label="击毁"></el-table-column>
    <el-table-column prop="spot" label="侦查"></el-table-column>
    <el-table-column prop="xp" label="经验"></el-table-column>
  </el-table>
</template>

<script>
import { ElMessage } from "element-plus";
import common from "@/common";

export default {
  name: "Record",

  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      if (this.playerParam.nick.length <= 0) {
        ElMessage.error("昵称不能为空！");
        return;
      }
      this.tableData = [];
      this.getRecord();
    },
    getRecord() {
      this.axios({
        methods: "get",
        url: "getRecord",
        params: {
          serv: this.playerParam.serv,
          nick: this.playerParam.nick,
          nation: this.playerParam.nation,
          level: this.playerParam.level,
          type: this.playerParam.type,
        },
      }).then(this.parserData);
    },
    parserData(response) {
      if (response.data.code == 40401) {
        ElMessage.error("找不到该用户");
        return;
      } else if (response.data.code == 40403) {
        ElMessage.error("未收录该用户数据，请稍后再试");
        return;
      }
      this.tableData = response.data.data;
    },
    processPR(value) {
      return common.calculatePersonRate(value);
    },
  },
  data() {
    return {
      playerParam: {
        serv: "1",
        nick: "",
        nation: "",
        level: "",
        type: "",
      },
      tableData: [],
    };
  },
};
</script>

<style>
#Record {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.VeryBad {
  text-align: center;
  color: #ffffff !important;
  background-color: #930d0d !important;
}

.Bad {
  text-align: center;
  color: #ffffff !important;
  background-color: #cd3333 !important;
}

.BelowAverage {
  text-align: center;
  color: #ffffff !important;
  background-color: #cc7a00 !important;
}

.Average {
  text-align: center;
  color: #ffffff !important;
  background-color: #ccb800 !important;
}

.AboveAverage {
  text-align: center;
  color: #ffffff !important;
  background-color: #849b24 !important;
}

.Good {
  text-align: center;
  color: #ffffff !important;
  background-color: #4d7326 !important;
}

.VeryGood {
  text-align: center;
  color: #ffffff !important;
  background-color: #4099bf !important;
}

.Great {
  text-align: center;
  color: #ffffff !important;
  background-color: #3972c6 !important;
}

.Unicum {
  text-align: center;
  color: #ffffff !important;
  background-color: #793db6 !important;
}

.SuperUnicum {
  text-align: center;
  color: #ffffff !important;
  background-color: #401070 !important;
}
</style>
