export default {
    calculateWinRate: function (value) {
        if (value == "100.00%") {
            return "SuperUnicum";
        } else if (value < "46.00%") {
            return "VeryBad";
        } else if (value < "47.00%") {
            return "Bad";
        } else if (value < "48.00%") {
            return "BelowAverage";
        } else if (value < "50.00%") {
            return "Average";
        } else if (value < "51.00%") {
            return "AboveAverage";
        } else if (value < "54.00%") {
            return "Good";
        } else if (value < "56.00%") {
            return "VeryGood";
        } else if (value < "60.00%") {
            return "Great";
        } else if (value < "65.00%") {
            return "Unicum";
        } else {
            return "SuperUnicum";
        }
    },
    calculatePersonRate: function (value) {
        if (value == '-') {
            return
        } else if (value < 299) {
            return "VeryBad";
        } else if (value < 449) {
            return "Bad";
        } else if (value < 649) {
            return "BelowAverage";
        } else if (value < 899) {
            return "Average";
        } else if (value < 1199) {
            return "AboveAverage";
        } else if (value < 1599) {
            return "Good";
        } else if (value < 1999) {
            return "VeryGood";
        } else if (value < 2449) {
            return "Great";
        } else if (value < 2899) {
            return "Unicum";
        } else {
            return "SuperUnicum";
        }
    },
    calculateFrags(value) {
        if (value < 0.5) {
            return "Bad";
        } else if (value < 0.73) {
            return "BelowAverage";
        } else if (value < 0.9) {
            return "Average";
        } else if (value < 1) {
            return "Good";
        } else if (value < 1.2) {
            return "VeryGood";
        } else if (value < 1.4) {
            return "Great";
        } else if (value < 3) {
            return "Unicum";
        } else {
            return "SuperUnicum";
        }
    },
}